import {
    Divider,
    Stack,
    Text,
    Container,
    Box,
    HStack,
    Card,
    CardHeader,
    CardBody,
    Flex,
    Image,
    List,
    ListItem,
    ListIcon,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import TagsArray from "./TagsArray";
import licensesArray from "./LicensesArray";


export default function Licenses({ color }) {
    const licenses = licensesArray();
    const options = TagsArray("LicensesTags");
    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (options.length > 0) {
            setSelected(options[0].value);
        }
    }, [options]);

    const handleSelected = (value) => {
        setSelected(value);
    };

    return (
        <>
            <Container maxW={"3xl"} id="licenses">
                <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    pb={{ base: 20, md: 36 }}
                >
                    <Stack align="center" direction="row" px={4}>
                        <HStack mx={4}>
                            <Text color={`${color}.400`} fontWeight={800}>
                                05
                            </Text>
                            <Text fontWeight={800}>Licenses & Certifications</Text>
                        </HStack>
                        <Divider orientation="horizontal" />
                    </Stack>
                    <Stack px={4} spacing={4}>
                        {licenses
                            .filter((exp) => exp.tags.includes(selected))
                            .map((exp) => (
                                <Fade bottom>
                                    <Card key={exp.company} size="sm">
                                        <CardHeader>
                                            <Flex justifyContent="space-between">
                                                <HStack>
                                                    <Image src={exp.image} h={50} />
                                                    <Box px={2} align="left">
                                                        <Text fontWeight={600}>{exp.company}</Text>
                                                        <Text>{exp.position}</Text>
                                                    </Box>
                                                </HStack>
                                                <Text px={2} fontWeight={300}>
                                                    {exp.duration}
                                                </Text>
                                            </Flex>
                                        </CardHeader>
                                        <CardBody>
                                            <Flex>
                                                <List align="left" spacing={3}>
                                                    {exp.listItems.map((item, index) => (
                                                        <ListItem key={index}>
                                                            <ListIcon
                                                                boxSize={6}
                                                                as={ChevronRightIcon}
                                                                color={`${color}.500`}
                                                            />
                                                            {item}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Flex>
                                        </CardBody>
                                    </Card>
                                </Fade>
                            ))}
                    </Stack>
                </Stack>
            </Container>
        </>
    );
}
